@use 'sass:math';

/*------------------------------------*\
  # POINTER EVENTS NONE

  Suppression des events clic sur tout 
  les enfants
\*------------------------------------*/
@mixin pointerEventsNone {
  & > * {
    @apply pointer-events-none;
  }
}

/*------------------------------------*/
  // #STRIP UNIT
/// Replace `$search` with `$replace` in `$string`
/// @author Kitty Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
/*------------------------------------*/
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  
  @return $string;
}

/*------------------------------------*/
  // #STRIP UNIT
  /// Remove the unit of a length
  /// @param {Number} $number - Number to remove unit from
  /// @return {Number} - Unitless number
/*------------------------------------*/
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}