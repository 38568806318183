@use '../3-tools/border' as border;
@use '../3-tools/icons' as icons;

@use '../6-components/c-form';

%storelocator-search-button {
  @include border.all;
    
  @include icons.size(22);
  @include icons.size(22, inside);
  @include icons.selector {
    @apply duration-0;
    &::before {
    }
  }
  @apply p-2.5 flex items-center justify-center relative;
  @apply transition-colors duration-200 delay-0;
  @apply rounded-none cursor-pointer no-underline;
  @apply bg-white;
  width: var(--storelocator-search-button);
  min-width: var(--storelocator-search-button);

  &:hover {
    @apply text-gray-800 border-gray-600 z-2;
  }
}

/*------------------------------------*\
  STORELOCATOR-SEARCH
\*------------------------------------*/
.c-storelocator-search {
  --storelocator-search-button: 50px;
  @apply flex;
}

  /*------------------------------------*\
    STORELOCATOR-SEARCH LOCATE ME
  \*------------------------------------*/
  .c-storelocator-search__locate-me {
    @extend %storelocator-search-button;

    @apply -mr-px;
    color: var(--theme-color);
  }

  /*------------------------------------*\
    STORELOCATOR-SEARCH RESULTS
  \*------------------------------------*/
  .leaflet-pelias-results {
    left: calc( (var(--storelocator-search-button) - 1px) * -1);
    width: calc(100% + var(--storelocator-search-button));
    @screen md {
      @apply left-0 w-full;
    }
  }

  /*------------------------------------*\
    STORELOCATOR-SEARCH INPUT
  \*------------------------------------*/
  .c-storelocator-search__input {
    --form-field-height: 46px;
    @apply relative z-1 grow;
    input {
      @extend %form-field;

      @screen md {
        @apply grow;
      }
      &::placeholder {
        @apply text-gray-600;
      }
      &:hover, &:focus {
        @apply z-2;
      }
    }
    .leaflet-pelias-control {
      @apply flex w-full;
    }

    .leaflet-pelias-search-icon {
      @extend %storelocator-search-button;
      @include icons.add('rechercher');
      @apply -ml-px;
      
      &.leaflet-pelias-loading {}
    }

    .leaflet-pelias-close {
      @extend %storelocator-search-button;
      @include icons.add('close');
      @apply -ml-px;
      @apply absolute right-0 top-0 bottom-0;
      &.leaflet-pelias-hidden {
        @apply hidden;
      }
    }

    .leaflet-pelias-list {
      @apply shadow-lg;
      .leaflet-pelias-result {
        @apply border-t-0 border-gray-300;
        @apply flex items-center;
        span.leaflet-pelias-layer-icon-container {
          @apply mr-1;
          .leaflet-pelias-layer-icon{
            @include icons.add('localisation');
            @include icons.size(16, inside);
            @apply flex;
          }
        }
      }
    }
  }