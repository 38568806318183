@use '../2-generic/icomoon';

@use '../3-tools/size' as size;

/*------------------------------------*\
  # ICON SIZE

  Défini les tailles de l'incon
\*------------------------------------*/
@mixin size($value, $iconInside: false) {

  @if $iconInside == inside {
    &:before {
      width: size.toRem($value);
      height: size.toRem($value);
      font-size: size.toRem($value);
    }
  } @else {
    @include selector {
      width: size.toRem($value);
      height: size.toRem($value);
      font-size: size.toRem($value);
    }
  }
}

/*------------------------------------*\
  # ICON SELECTEUR

  Permet de selectionner des enfants de
  type icon
\*------------------------------------*/
@mixin selector {
  [class^="c-icon--"], [class*=" c-icon--"] {
    @content;
  }
}

/*------------------------------------*\
  # ICON ADD

  ajout un icon à l'élément en cours
\*------------------------------------*/
@mixin add ($icon: unknow) {

  &::before {
    @extend .c-icon;
  }
  @extend .c-icon--#{$icon};
}