@use '../3-tools/border' as border;
@use '../3-tools/icons' as icons;
@use '../3-tools/size' as size;
@use '../3-tools/wrapper' as wrapper;

@use '../5-objects/o-wrapper';
@use '../5-objects/o-hidden';

@use '../6-components/c-text';
@use '../6-components/c-button';
@use '../6-components/c-lists';


/*------------------------------------*\
  #FORM FIELD DEFAULT
\*------------------------------------*/
%form-field {

  @include border.all;
  @apply p-3 bg-white font-light;
  @apply transition duration-200;
  @apply w-full;

  color: var(--form-field-text-color);
  border-color: var(--form-field-border-color);
  height: var(--form-field-height);
  border-radius: var(--form-field-rounded);

  &:hover {
    --form-field-border-color: theme('colors.black');
  }
  &:focus {
    @apply outline-none;
    border-color: var(--current-color);
  }

  &[disabled], &[readonly] {
    @apply bg-gray-100;
    @apply cursor-not-allowed;
    &:hover, &:focus {
      --form-field-border-color: theme('colors.gray.300');
    }
  }

}

/*------------------------------------*\
  #FORM
\*------------------------------------*/
.c-form {
  --form-field-height: 46px;
  @extend .o-wrapper;
  @include wrapper.direction(column);
  @include wrapper.gapRow(xl, tablet);
}
.c-form--inline {
  @apply w-auto mx-0;
}

/*------------------------------------*\
  #FORM DIALOG
\*------------------------------------*/
.c-form--dialog {
  @include wrapper.gapRow(xs);
}

  /*------------------------------------*\
    #FORM FIELDSET
  \*------------------------------------*/
  .c-form__fieldset {
    @apply w-full;
  }

  .c-form__fieldset--footer {
    @extend .o-wrapper;
    @include wrapper.items(flex-start);
  }

    /*------------------------------------*\
      #FORM COLUMNS
    \*------------------------------------*/
    .c-form__columns {
      @extend .o-wrapper;
      @include wrapper.gapRow(xs, tablet);
      @include wrapper.display(grid, tablet);
      @include wrapper.columns(2, tablet);
    }

      /*------------------------------------*\
        #FORM FIELD

        wrapper de champ du formulaire
      \*------------------------------------*/
      .c-form__field {

        --form-field-border-color: theme('colors.gray.300');
        --form-field-text-color: theme('colors.gray.800');
        --form-label-text-color: theme('colors.gray.800');
        --form-icon-text-color: theme('colors.gray.800');
        --form-field-rounded: 0;

        @extend .o-wrapper;
        @include wrapper.gap(xxxxs);
        @include wrapper.items(start);
        
        @apply w-full;
        @apply relative;
      }

      /*------------------------------------*\
        #FORM COLSPAN

        Permet de mettre un element sur 2 cols
        cn c-form__columns
      \*------------------------------------*/
      .c-form__field--colspan {
        @include wrapper.span(column, 2);
      }
      /*------------------------------------*\
        #FORM FIELD THEME
      \*------------------------------------*/
      .c-form__field--rounded {
        --form-field-rounded: theme('borderRadius.lg');
      }
      
      /*------------------------------------*\
        #FORM FIELD THEME
      \*------------------------------------*/
      .c-form__field--theme {
        --form-field-border-color: var(--theme-color);
        --form-field-text-color: var(--theme-color);
        --form-label-text-color: var(--theme-color);
        --form-icon-text-color: var(--theme-color);
        option:hover {
          background-color: var(--theme-color);
          box-shadow: 0 0 10px 100px var(--theme-color) inset !important;
        }
      }
      /*------------------------------------*\
        #FORM FIELD ERROR
      \*------------------------------------*/
      .c-form__field--error {
        --form-field-border-color: theme('colors.alert.DEFAULT');
        --form-label-text-color: theme('colors.alert.dark');
        --form-field-text-color: theme('colors.alert.dark');
        --form-icon-text-color: theme('colors.alert.dark');
        .c-form__icon.c-icon--alerte {
          @apply flex;
        }
        .c-form__error[aria-hidden=true] {
          @apply block;
        }
      }

      /*------------------------------------*\
        #FORM FIELD INLINE
      \*------------------------------------*/
      .c-form__field--inline {
        @include wrapper.itemInline(flex-start, xxxs);
        @apply text-left;
      }

      /*------------------------------------*\
        #FORM FIELD START
      \*------------------------------------*/
      .c-form__field--start {
        @apply justify-start
      }

      /*------------------------------------*\
        #FORM FIELD END
      \*------------------------------------*/
      .c-form__field--end {
        @screen md {
          @apply justify-end
        }
      }

      /*------------------------------------*\
        #FORM FIELD END
      \*------------------------------------*/
      .c-form__field--drop-zone {
        @include wrapper.direction(column);
        @include wrapper.gap(xxs);
        @include wrapper.items(center);
      }

        /*------------------------------------*\
          #FORM FIELD INNER
        \*------------------------------------*/
        .c-form__field-inner {
          @extend .o-wrapper;
          @include wrapper.itemInline(flex-start, xxxs);
        }

        /*------------------------------------*\
          #FORM FIELD DROP-ZONE
        \*------------------------------------*/
        .c-form__drop-zone {
          @apply bg-gray-100 border-gray-300 border-dashed border-2;
          @apply rounded-2xl overflow-hidden relative;

          input {
            @apply opacity-0 relative z-2;
            @apply w-full h-full cursor-pointer;
          }

          .c-form__drop-zone-label {
            @apply absolute inset-0 z-1;
            
            @extend .o-wrapper;
            @include wrapper.centered(column);
          }
        }

        /*------------------------------------*\
          #FORM - TITLE
        \*------------------------------------*/
        .c-form__title {
          @extend .c-text--caption-lg;
          @apply mb-5;
        }

        /*------------------------------------*\
          #FORM - LABEL
        \*------------------------------------*/
        .c-form__label {
          @extend .c-text--caption;
          @apply cursor-pointer;

          &:not(.c-button) {
            color: var(--form-label-text-color);
          }

          &.c-form__label--tiny {
            @extend .c-text--sm;
            @extend .c-text--light;
            @apply normal-case;
          }
        }

        /*------------------------------------*\
          #FORM - INPUT
        \*------------------------------------*/
        .c-form__input {
          @extend %form-field;
          &::placeholder {
            @apply text-gray-600;
          }
        }
        .c-form__input--small {
          @apply p-1;
        }


        /*------------------------------------*\
          #FORM - BUTTON
        \*------------------------------------*/
        .c-form__button {
          input {
            @apply ml-6 -z-1;
            @apply absolute;
          }
          label {
            @include border.all;
            border-radius: 100px;
            @apply py-2 px-3 bg-white;
            @extend .c-text--medium;
            @apply flex items-center gap-1;
            @apply transition duration-300 ease-in-out;

            &:hover {
              @apply bg-gray-700 text-white;
            }
          }
          [type=checkbox]:checked + label {
            @apply bg-gray-700 text-white;
          }
        }

        /*------------------------------------*\
          #FORM - DESCRIPTION
        \*------------------------------------*/
        .c-form__description {
          @extend .c-text--legend;
        }

        /*------------------------------------*\
          #FORM - ERROR
        \*------------------------------------*/
        .c-form__errors, 
        .c-form__error {
          @apply text-alert-dark;
          @extend .c-text--xxs;
          @extend .c-text--medium;

          &[aria-hidden=true] {
            @apply hidden;
          }
        }
        .c-form__errors[aria-hidden=false] {
          @apply pt-gutter;
        }

          /*------------------------------------*\
            #FORM - ERROR LISTS
          \*------------------------------------*/
          .c-form__errors-list {
            @extend .c-list;
            @apply text-left pt-2;
          }

        /*------------------------------------*\
          #FORM - TEXTEAREA
        \*------------------------------------*/
        .c-form__textarea {
          --form-field-height: auto;
          @extend %form-field;
        }

        /*------------------------------------*\
          #FORM - SELECT
        \*------------------------------------*/
        .c-form__select {
          @extend %form-field;
        }
        .c-form__select--medium {
          @apply font-medium;
        }

        /*------------------------------------*\
          #FORM INPUT ICON-BEFORE
        \*------------------------------------*/
        .c-form__icon-before {
          @apply pl-12;
        }
        /*------------------------------------*\
          #FORM INPUT ICON-AFTER
        \*------------------------------------*/
        .c-form__icon-after {
          @apply pr-12;
        }

        /*------------------------------------*\
          #FORM FILE-NAME
        \*------------------------------------*/
        .c-form__file-name {
          @extend .c-text--sm;
          @extend .c-text--medium;
        }

        /*------------------------------------*\
          #FORM ICON
        \*------------------------------------*/
        .c-form__icon {
          @include icons.size(20, inside);

          @extend .o-wrapper;
          @include wrapper.itemInline(center, xxxxs);
          @include wrapper.gapRow(xxxxs);

          @apply bg-white;
          @apply self-end;
          @apply mr-px #{!important};

          border-radius: var(--form-field-rounded);
          color: var(--form-icon-text-color);
          height: calc(var(--form-field-height) - 2px);
          min-width: calc(var(--form-field-height) - 2px);
          margin-top: calc((var(--form-field-height) * -1) - var(--wrapper-gap-row) + 1px) !important;

          &:not(button) {
            @apply pointer-events-none;
          }
          &.c-icon--alerte {
            @apply hidden;
          }
          &.c-form__icon--before {
            @apply ml-px self-start bg-transparent;
          }
        }


        /*------------------------------------*\
          #FORM FILE
        \*------------------------------------*/
        .c-form__file {

          .c-form__label {
            @apply cursor-pointer;
          }
          .c-form__input {
            @apply border-none overflow-hidden p-0 absolute;
            @apply transform -translate-x-full;
            @apply w-0 h-0;
          }

          [data-js-trigger=form-file--remove] {
            @apply hidden;

          }
          &.c-form__file--selected [data-js-trigger=form-file--remove] {
            @apply flex;
          }
        }
        