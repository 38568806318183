/*------------------------------------*\
  #HIDDEN VISUALLY

  Cache un élément visuellement en front
  mais reste disponible d'un point de 
  vue accessibilité
\*------------------------------------*/
@mixin hidden {
  @apply border-none h-px w-px overflow-hidden p-0 absolute;
  @apply transform -translate-x-full;
}
.o-hidden {
  @include hidden;
}
.o-hidden-visually {
  @include hidden;
  
  &:focus-within {
    @apply -translate-x-1/2 h-auto w-auto left-1/2 top-0;
    @apply bg-white border border-solid border-gray-800;
    @apply p-4;

    z-index: 1000;
  }
}