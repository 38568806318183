@use '../3-tools/border' as border;
@use '../3-tools/typography' as typography;
@use '../3-tools/size' as size;
@use '../3-tools/wrapper' as wrapper;

@use '../4-elements/typography' as title;

@use '../5-objects/o-wrapper';

@use '../6-components/c-text';

/*------------------------------------*\
# TITLE MAIN - type h1
\*------------------------------------*/
.c-title--main { @extend h1; }

/*------------------------------------*\
# TITLE H2
\*------------------------------------*/
.c-title--h2 { @extend h2; @apply uppercase; }

/*------------------------------------*\
# TITLE H3
\*------------------------------------*/
.c-title--h3 { @extend h3; }

/*------------------------------------*\
# TITLE H4
\*------------------------------------*/
.c-title--h4 { @extend h4; }

/*------------------------------------*\
# TITLE H5
\*------------------------------------*/
.c-title--h5 { @extend h5; }

/*------------------------------------*\
# TITLE H5 MD
\*------------------------------------*/
.c-title--h5-md { 
  @screen md {
    @include typography.fontFamily('Acumin');
    @include typography.fontSize(16px, 120%);
    @apply font-light normal-case;
  }
}

/*------------------------------------*\
# TITLE H6
\*------------------------------------*/
.c-title--h6 { @extend h6; }

/*------------------------------------*\
# TITLE SECTION

titre principal des section
titre des banner classique
\*------------------------------------*/
.c-title--section {
  @include typography.fontFamily('Acumin');
  @include typography.fontSize(28 , 100%);

  @apply font-bold uppercase; 

  @screen lg {
    @include typography.fontSize(32, 100%);
  }

  sup {
    font-size: 50%;
    vertical-align: super;
  }
}

/*------------------------------------*\
# TITLE CARD SM - small
\*------------------------------------*/
.c-title--card-sm {
  @include typography.fontFamily('Acumin');
  @include typography.fontSize(16, 120%);

  @apply font-light normal-case; 

  @screen lg {
    @include typography.fontSize(18);
  }
}

/*------------------------------------*\
# TITLE CARD MD - medium
\*------------------------------------*/
.c-title--card-md {
  @include typography.fontFamily('Acumin');
  @include typography.fontSize(20, 110%);

  @apply font-light normal-case; 

  @screen lg {
    @include typography.fontSize(28);
  }
}

/*------------------------------------*\
# TITLE CARD LG - large
\*------------------------------------*/
.c-title--card-lg {
  @include typography.fontFamily('Acumin');
  @include typography.fontSize(24px, 110%);

  @apply font-normal uppercase; 

  @screen lg {
    @include typography.fontSize(30px, 110%);
  }
}

/*------------------------------------*\
# TITLE CARD XL - extra large
\*------------------------------------*/
.c-title--card-xl {
  @include typography.fontFamily('Acumin');
  @include typography.fontSize(24, 110%);

  @apply font-light normal-case; 

  @screen lg {
    @include typography.fontSize(34);
  }
}

/*------------------------------------*\
# TITLE NAV
\*------------------------------------*/
.c-title--nav {
  @include typography.fontFamily('Acumin');
  @include typography.fontSize(16, 110%);

  @apply font-bold normal-case no-underline text-left; 
  
  @screen lg {
    @include typography.fontSize(16);
  }
}

/*------------------------------------*\
# TITLE LINE

title avec border left.
Présent dans 
  .m-table-of-contents
  .c-product-nav-details
\*------------------------------------*/
.c-title--line {
  @include border.left(border-gray-800);
 
  @extend .c-text--caption-lg;

  @apply no-underline text-gray-800 pl-5 inline-flex;

  &:focus {
    @apply text-gray-800;
  }
  
  &[aria-current=true],
  &:hover {
    color: var(--theme-color);
    border-color: var(--theme-color);
  }
}

/*------------------------------------*\
# TITLE ANIMATION

title avec border bottom.
Présent dans 
  page céliane
\*------------------------------------*/
.c-title--celiane {
  @include typography.fontFamily('Acumin');
  @include typography.fontSize(28, 110%);
  
  @apply font-bold normal-case text-center;
  color: var(--theme-color);
  
  @screen lg {
    @include typography.fontSize(40);
    @apply text-left;
  }
  span {
    @apply block;
  }
  @screen lg {
    &::after {
      @apply block;
      content: '';
      width: 28px;
      height: 1px;
      background-color: var(--theme-color);
    }
  }
}

/*------------------------------------*\
# TITLE ANIMATION FONCTIONS

title avec border bottom.
Présent dans 
  page céliane
\*------------------------------------*/
.c-title--celiane-fonctions {
  @include typography.fontFamily('Acumin');
  @include typography.fontSize(26, 100%);
  
  @apply font-bold normal-case text-center;
  color: var(--theme-color);
  
  @screen lg {
    @include typography.fontSize(30);
  }
  span {
    @apply block;
  }
  sup {
    font-size: 60%;
  }
}