@use '../3-tools/border' as border;
@use '../3-tools/icons' as icons;
@use '../3-tools/wrapper' as wrapper;

@use '../5-objects/o-wrapper';

@use '../6-components/c-button';
@use '../6-components/c-title';

/*------------------------------------*\
  STORELOCATOR-ITEM
\*------------------------------------*/
.c-storelocator-item {
  @extend .o-wrapper;
  @include wrapper.gap(xs);
  @include wrapper.items(flex-start);
  @include border.bottom;

  @apply pl-8 py-5 pr-4;
  @apply transition-colors duration-300;
  @apply cursor-pointer;

  &:hover, &.active {
    @apply bg-gray-100;
  }
  &.active {
    @apply cursor-auto;
  }
  &:last-child {
    @include border.none(bottom);
  }
}

  /*------------------------------------*\
    STORELOCATOR-ITEM BACK
  \*------------------------------------*/
  .c-storelocator-item__back {
    @extend .c-button;
    @extend .c-button--simple;
    @extend .c-button--normal;
    @extend .c-button--xs;
  }

  /*------------------------------------*\
    STORELOCATOR-ITEM LOGO
  \*------------------------------------*/
  .c-storelocator-item__logo {
    @apply hidden;
    max-width: 100px;
  }

  /*------------------------------------*\
    STORELOCATOR-ITEM INFOS
  \*------------------------------------*/
  .c-storelocator-item__infos {}

    /*------------------------------------*\
      STORELOCATOR-ITEM TITLE
    \*------------------------------------*/
    .c-storelocator-item__title {
      @extend .c-title--h3;
      @extend .o-wrapper;
      @include wrapper.gap(xxxs);
      @include wrapper.direction(row);
      @include wrapper.items(center);
      @media screen and (min-width: 768px) and (max-height: 860px) {
        @apply text-base;
      }
    }

      /*------------------------------------*\
        STORELOCATOR-ITEM DISTANCE
      \*------------------------------------*/
      .c-storelocator-item__distance {
        @extend .c-text--xxs;
        @extend .c-text--light;
      }


    /*------------------------------------*\
      STORELOCATOR-ITEM INFOS
    \*------------------------------------*/
    .c-storelocator-item__infos {
      @extend .o-wrapper;
      @include wrapper.gap(xxxxs);
      @include wrapper.items(flex-start);
    }

      /*------------------------------------*\
        STORELOCATOR-ITEM LINE
      \*------------------------------------*/
      .c-storelocator-item__line {
        @extend .o-wrapper;
        @include wrapper.gap(xxxs);
        @include wrapper.direction(row);

        @extend .c-text--sm;
        @extend .c-text--light;

        @include icons.selector {
          @apply mt-0.5;
        }
      }

    /*------------------------------------*\
      STORELOCATOR-ITEM ACTIONS
    \*------------------------------------*/
    .c-storelocator-item__actions {
      @extend .o-wrapper;
      @include wrapper.gap(xxxxs);
      @include wrapper.items(flex-start);

      @apply hidden;
    }
  
    /*------------------------------------*\
      STORELOCATOR-ITEM ACTIVE
    \*------------------------------------*/
    .c-storelocator-item:not(.active) .c-storelocator-item__active {
    @apply hidden;
  }