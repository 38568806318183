@use '../3-tools/size' as size;
@use '../3-tools/wrapper' as wrapper;
@use '../3-tools/border' as border;

@use '../5-objects/o-wrapper';

@use '../6-components/c-text';

/*------------------------------------*\
  #LIST list
\*------------------------------------*/
.c-list {
  @apply list-inside;
  @apply flex flex-col gap-2;
  li {
    @apply pl-3;
    &::before {
      @apply inline-block mr-1 -ml-2;
      
      content: '•';
    }
  }
}
ol.c-list {
  @apply list-decimal;
  li {
    @apply pl-0;
    &::before {
      @apply hidden;
    }
  }
}

/*------------------------------------*\
  #LIST SEP
\*------------------------------------*/
.c-list-sep {
  @apply flex flex-col gap-3;
  li {
    @apply pb-3 border-b border-solid border-gray-300;
  }
}

/*------------------------------------*\
  #LIST list
\*------------------------------------*/
.c-list-responsive {
  @extend .o-wrapper;
  @include wrapper.gap(xxxxs);
  @include wrapper.items(center);

  @include wrapper.direction(row, tablet);
  @include wrapper.display(grid, tablet);
  @include wrapper.column(2, tablet);
  @include wrapper.gapColumn(xs, tablet);

  @include wrapper.display(flex, desktop);
  @include wrapper.justify(center, desktop);

  @apply flex-wrap;

  li {
    @screen md {
      &:nth-child(odd) {
        @apply text-right;
        a {
          @include border.right(border-gray-800);
        }
      }
    }
    &:last-child {
      @screen md {
        a {
          @include border.none(right);
        }
      }
    }
  }
  a {

  
    @extend .c-text--caption-lg;

    @apply no-underline text-gray-800 pr-5 inline-flex;

    &:focus {
      @apply text-gray-800;
    }
    
    &[aria-current=true],
    &:hover {
      color: var(--theme-color);
    }

    @screen lg {
      @include border.right(border-gray-800);
    }
  }
}
