/*------------------------------------*\
  # BORDER FOOTER
\*------------------------------------*/
@mixin footer {
  @apply border-t border-solid border-white border-opacity-20;
  @screen lg {
    @apply border-0;
  }
}

/*------------------------------------*\
  # BORDER BOTTOM
\*------------------------------------*/
@mixin border ($color: border-gray-300) {
  @apply border-solid #{$color};
}

/*------------------------------------*\
  # BORDER ALL
\*------------------------------------*/
@mixin all ($color: border-gray-300) {
  @include border($color);
  @apply border;
}

/*------------------------------------*\
  # BORDER TOP
\*------------------------------------*/
@mixin top ($color: border-gray-300) {
  @include border($color);
  @apply border-t;
}

/*------------------------------------*\
  # BORDER BOTTOM
\*------------------------------------*/
@mixin bottom ($color: border-gray-300) {
  @include border($color);
  @apply border-b;
}

/*------------------------------------*\
  # BORDER LEFT
\*------------------------------------*/
@mixin left ($color: border-gray-300) {
  @include border($color);
  @apply border-l;
}

/*------------------------------------*\
  # BORDER RIGHT
\*------------------------------------*/
@mixin right ($color: border-gray-300) {
  @include border($color);
  @apply border-r;
}

/*------------------------------------*\
  # BORDER NONE
\*------------------------------------*/
@mixin none ($side: null) {
  @if $side == null {
    @apply border-0;
  } @else {
    border-#{$side}: 0;
  }
}