@use '../3-tools/size' as size;
/*------------------------------------*\
  #CUSTOMISATION SCROLLBAR
\*------------------------------------*/
@mixin scrollbar ($style:default) {
  $thumbColor: theme('colors.gray.400');
  $trackColor: theme('colors.white');
  $width: 7px;
  $border: 2px;
  
  @if $style == big {
    $width: 12px;
    $border: 3px;
  } 

  /* Firefox */
  & {
    scrollbar-width: thin;
    scrollbar-color: $thumbColor $trackColor;
  }

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: $width;
    height: $width;
  }

  &::-webkit-scrollbar-track {
    background: $trackColor;
  }

  &::-webkit-scrollbar-thumb {
    background: $thumbColor;
    border-radius: $width;
    border: #{$border} solid #{theme('colors.white')};
    cursor: pointer;
  } 
}