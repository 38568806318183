@use '../3-tools/wrapper' as wrapper;

/*------------------------------------*\
  # WRAPPER
\*------------------------------------*/
.o-wrapper {
  --wrapper-gap: #{theme('gap.gutter--mobile')};
  --wrapper-gap-column: var(--wrapper-gap);
  --wrapper-gap-row: var(--wrapper-gap);
  --wrapper-display: flex;
  --wrapper-direction: column;
  --wrapper-justify: unset;
  --wrapper-items: unset;
  --wrapper-wrap: unset;

  @apply max-h-full;
  @apply w-full mx-auto;

  row-gap: var(--wrapper-gap-row);
  column-gap: var(--wrapper-gap-column);

  display: var(--wrapper-display, flex);
  flex-direction: var(--wrapper-direction, column);
  justify-content: var(--wrapper-justify, unset);
  align-items: var(--wrapper-items, unset);
  flex-wrap:  var(--wrapper-wrap, unset);

  @screen md {
    --wrapper-gap: #{theme('gap.gutter')};
  }
}
/*------------------------------------*\
  # WRAPPER HEIGHT
\*------------------------------------*/
.o-wrapper--height-max { @apply h-max; }

/*------------------------------------*\
  # WRAPPER WRAP
\*------------------------------------*/
.o-wrapper--wrap { --wrapper-wrap: wrap; }

/*------------------------------------*\
  # WRAPPER DIRECTION - MODIFIER

  Gestion de l'affichage d'élement en mode
  row
\*------------------------------------*/
.o-wrapper--direction-column { @include wrapper.direction(column); }
.o-wrapper--direction-column--tablet { @include wrapper.direction(column, tablet); }
.o-wrapper--direction-column--desktop { @include wrapper.direction(column, desktop); }

.o-wrapper--direction-row { @include wrapper.direction(row); }
.o-wrapper--direction-row--tablet { @include wrapper.direction(row, tablet); }
.o-wrapper--direction-row--desktop { @include wrapper.direction(row, desktop); }

/*------------------------------------*\
  # WRAPPER JUSTIFY - MODIFIER
\*------------------------------------*/
.o-wrapper--justify-between { @include wrapper.justify(space-between); }
.o-wrapper--justify-between--tablet { @include wrapper.justify(space-between, tablet); }
.o-wrapper--justify-between--desktop { @include wrapper.justify(space-between, desktop); }

.o-wrapper--justify-start { @include wrapper.justify(flex-start); }
.o-wrapper--justify-start--tablet { @include wrapper.justify(flex-start, tablet); }
.o-wrapper--justify-start--desktop { @include wrapper.justify(flex-start, desktop); }

.o-wrapper--justify-end { @include wrapper.justify(flex-end); }
.o-wrapper--justify-end--tablet { @include wrapper.justify(flex-end, tablet); }
.o-wrapper--justify-end--desktop { @include wrapper.justify(flex-end, desktop); }

.o-wrapper--justify-center { @include wrapper.justify(center); }
.o-wrapper--justify-center--tablet { @include wrapper.justify(center, tablet); }
.o-wrapper--justify-center--desktop { @include wrapper.justify(center, desktop); }

/*------------------------------------*\
  # WRAPPER ITEMS - MODIFIER
\*------------------------------------*/
.o-wrapper--items-start { @include wrapper.items(flex-start); }
.o-wrapper--items-start--tablet { @include wrapper.items(flex-start, tablet); }
.o-wrapper--items-start--desktop { @include wrapper.items(flex-start, desktop); }

.o-wrapper--items-end { @include wrapper.items(flex-end); }
.o-wrapper--items-end--tablet { @include wrapper.items(flex-end, tablet); }
.o-wrapper--items-end--desktop { @include wrapper.items(flex-end, desktop); }

.o-wrapper--items-center { @include wrapper.items(center); }
.o-wrapper--items-center--tablet { @include wrapper.items(center, tablet); }
.o-wrapper--items-center--desktop { @include wrapper.items(center, desktop); }

/*------------------------------------*\
  # WRAPPER LAYOUT - MODIFIER

  Affichage de type grid, mais avec gestion
  des gap en padding - permet le retour 
  auto des enfants
\*------------------------------------*/
.o-wrapper--layout {
  @include wrapper.display(layout);
}

.o-wrapper--layout__sep { @apply basis-full h-0 p-0; }
.o-wrapper--layout__sep--tablet { 
  @apply hidden;
  @screen md { @apply basis-full h-0 p-0 block; } 
}
.o-wrapper--layout__sep--desktop { 
  @apply hidden;
  @screen lg { @apply basis-full h-0 p-0 block; } 
}


/*------------------------------------*\
  # WRAPPER GRID - MODIFIER
\*------------------------------------*/
.o-wrapper--grid { @include wrapper.display(grid); }
.o-wrapper--grid--tablet { @include wrapper.display(grid, tablet); }
.o-wrapper--grid--desktop { @include wrapper.display(grid, desktop); }

/*------------------------------------*\
  # WRAPPER GRID SPAN
\*------------------------------------*/

.o-wrapper--grid-span-column-1 { @include wrapper.span(column, 1); }
.o-wrapper--grid-span-column-1--tablet { @include wrapper.span(column, 1, tablet); }
.o-wrapper--grid-span-column-1--desktop { @include wrapper.span(column, 1, desktop); }

.o-wrapper--grid-span-column-2 { @include wrapper.span(column, 2); }
.o-wrapper--grid-span-column-2--tablet { @include wrapper.span(column, 2, tablet); }
.o-wrapper--grid-span-column-2--desktop { @include wrapper.span(column, 2, desktop); }

.o-wrapper--grid-span-column-3 { @include wrapper.span(column, 3); }
.o-wrapper--grid-span-column-3--tablet { @include wrapper.span(column, 3, tablet); }
.o-wrapper--grid-span-column-3--desktop { @include wrapper.span(column, 3, desktop); }

.o-wrapper--grid-span-column-4 { @include wrapper.span(column, 4); }
.o-wrapper--grid-span-column-4--tablet { @include wrapper.span(column, 4, tablet); }
.o-wrapper--grid-span-column-4--desktop { @include wrapper.span(column, 4, desktop); }

.o-wrapper--grid-span-row-1 { @include wrapper.span(row, 1); }
.o-wrapper--grid-span-row-1--tablet { @include wrapper.span(row, 1, tablet); }
.o-wrapper--grid-span-row-1--desktop { @include wrapper.span(row, 1, desktop); }

.o-wrapper--grid-span-row-2 { @include wrapper.span(row, 2); }
.o-wrapper--grid-span-row-2--tablet { @include wrapper.span(row, 2, tablet); }
.o-wrapper--grid-span-row-2--desktop { @include wrapper.span(row, 2, desktop); }

.o-wrapper--grid-span-row-3 { @include wrapper.span(row, 3); }
.o-wrapper--grid-span-row-3--tablet { @include wrapper.span(row, 3, tablet); }
.o-wrapper--grid-span-row-3--desktop { @include wrapper.span(row, 3, desktop); }

.o-wrapper--grid-span-row-4 { @include wrapper.span(row, 4); }
.o-wrapper--grid-span-row-4--tablet { @include wrapper.span(row, 4, tablet); }
.o-wrapper--grid-span-row-4--desktop { @include wrapper.span(row, 4, desktop); }

/*------------------------------------*\
  # WRAPPER COLUMNS - MODIFIER

  Gestion de l'affichage d'élements en
  haut vers le bas sur plusieurs colonnes
\*------------------------------------*/
.o-wrapper--column {
  @include wrapper.display(column);
}
/*------------------------------------*\
  # WRAPPER COLUMNS COUN - MODIFIER

  Gestion de l'affichage d'élements en
  haut vers le bas sur plusieurs colonnes
\*------------------------------------*/
.o-wrapper--column-1 { @include wrapper.column(1); }
.o-wrapper--column-1--tablet { @include wrapper.column(1, tablet); }
.o-wrapper--column-1--desktop { @include wrapper.column(1, desktop); }

.o-wrapper--column-2 { @include wrapper.column(2); }
.o-wrapper--column-2--tablet { @include wrapper.column(2, tablet); }
.o-wrapper--column-2--desktop { @include wrapper.column(2, desktop); }

.o-wrapper--column-3 { @include wrapper.column(3); }
.o-wrapper--column-3--tablet { @include wrapper.column(3, tablet); }
.o-wrapper--column-3--desktop { @include wrapper.column(3, desktop); }

.o-wrapper--column-4 { @include wrapper.column(4); }
.o-wrapper--column-4--tablet { @include wrapper.column(4, tablet); }
.o-wrapper--column-4--desktop { @include wrapper.column(4, desktop); }

/*------------------------------------*\
/*------------------------------------*\
  # WRAPPER - GAP
\*------------------------------------*\
\*------------------------------------*/
/*------------------------------------*\
  #WRAPPER GAP NONE - none 0px
\*------------------------------------*/
.o-wrapper--gap-none { @include wrapper.gapRow(none); @include wrapper.gapColumn(none); }
.o-wrapper--gap-row-none { @include wrapper.gapRow(none); }
.o-wrapper--gap-column-none { @include wrapper.gapColumn(none); }

/*------------------------------------*\
#WRAPPER GAP EXTRA EXTRA EXTRA SMALL - xxxxs 4px
\*------------------------------------*/
.o-wrapper--gap-row-xxxxs { @include wrapper.gapRow(xxxxs); }
.o-wrapper--gap-column-xxxxs { @include wrapper.gapColumn(xxxxs); }

/*------------------------------------*\
#WRAPPER GAP EXTRA EXTRA SMALL - xxxs 8px
\*------------------------------------*/
.o-wrapper--gap-row-xxxs { @include wrapper.gapRow(xxxs); }
.o-wrapper--gap-column-xxxs { @include wrapper.gapColumn(xxxs); }

/*------------------------------------*\
#WRAPPER GAP EXTRA EXTRA SMALL - xxs 12px
\*------------------------------------*/
.o-wrapper--gap-row-xxs { @include wrapper.gapRow(xxs); }
.o-wrapper--gap-column-xxs { @include wrapper.gapColumn(xxs); }

/*------------------------------------*\
#WRAPPER GAP EXTRA SMALL - xs 20px
\*------------------------------------*/
.o-wrapper--gap-row-xs { @include wrapper.gapRow(xs); }
.o-wrapper--gap-column-xs { @include wrapper.gapColumn(xs); }

.o-wrapper--gap-row-xs--tablet { @include wrapper.gapRow(xs, tablet); }
.o-wrapper--gap-column-xs--tablet { @include wrapper.gapColumn(xs, tablet); }

/*------------------------------------*\
#WRAPPER GAP SMALL - sm 28px
\*------------------------------------*/
.o-wrapper--gap-row-sm { @include wrapper.gapRow(sm); }
.o-wrapper--gap-column-sm { @include wrapper.gapColumn(sm); }

.o-wrapper--gap-row-sm--tablet { @include wrapper.gapRow(sm, tablet); }
.o-wrapper--gap-column-sm--tablet { @include wrapper.gapColumn(sm, tablet); }


/*------------------------------------*\
#WRAPPER GAP MEDIUM - md 32px
\*------------------------------------*/
.o-wrapper--gap-row-md { @include wrapper.gapRow(md); }
.o-wrapper--gap-column-md { @include wrapper.gapColumn(md); }

.o-wrapper--gap-row-md--tablet { @include wrapper.gapRow(md, tablet); }
.o-wrapper--gap-column-md--tablet { @include wrapper.gapColumn(md, tablet); }

.o-wrapper--gap-row-md--desktop { @include wrapper.gapRow(md, desktop); }
.o-wrapper--gap-column-md--desktop { @include wrapper.gapColumn(md, desktop); }

/*------------------------------------*\
#WRAPPER GAP LARGE - lg 48px
\*------------------------------------*/
.o-wrapper--gap-row-lg { @include wrapper.gapRow(lg); }
.o-wrapper--gap-column-lg { @include wrapper.gapColumn(lg); }

.o-wrapper--gap-row-lg--tablet { @include wrapper.gapRow(lg, tablet); }
.o-wrapper--gap-column-lg--tablet { @include wrapper.gapColumn(lg, tablet); }

.o-wrapper--gap-row-lg--desktop { @include wrapper.gapRow(lg, desktop); }
.o-wrapper--gap-column-lg--desktop { @include wrapper.gapColumn(lg, desktop); }

/*------------------------------------*\
#WRAPPER GAP EXTRA LARGE - xl 56px
\*------------------------------------*/
.o-wrapper--gap-row-xl { @include wrapper.gapRow(xl); }
.o-wrapper--gap-column-xl { @include wrapper.gapColumn(xl); }

.o-wrapper--gap-row-xl--tablet { @include wrapper.gapRow(xl, tablet); }
.o-wrapper--gap-column-xl--tablet { @include wrapper.gapColumn(xl, tablet); }

.o-wrapper--gap-row-xl--desktop { @include wrapper.gapRow(xl, desktop); }
.o-wrapper--gap-column-xl--desktop { @include wrapper.gapColumn(xl, desktop); }

/*------------------------------------*\
#WRAPPER GAP EXTRA LARGE - xxl 112px
\*------------------------------------*/
.o-wrapper--gap-row-xxl { @include wrapper.gapRow(xxl); }
.o-wrapper--gap-column-xxl { @include wrapper.gapColumn(xxl); }

.o-wrapper--gap-row-xxl--tablet { @include wrapper.gapRow(xxl, tablet); }
.o-wrapper--gap-column-xxl--tablet { @include wrapper.gapColumn(xxl, tablet); }

.o-wrapper--gap-row-xxl--desktop { @include wrapper.gapRow(xxl, desktop); }
.o-wrapper--gap-column-xxl--desktop { @include wrapper.gapColumn(xxl, desktop); }

/*------------------------------------*\
/*------------------------------------*\
  # WRAPPER - COMPOSITION
\*------------------------------------*\
\*------------------------------------*/
/*------------------------------------*\
  # WRAPPER PRODUCT DETAILS
\*------------------------------------*/
.o-wrapper--product-details {
  @extend .o-wrapper;
  @include wrapper.direction(column);
  @include wrapper.gapRow(none);
  @include wrapper.gapRow(xl, desktop);
}
/*------------------------------------*\
  # WRAPPER PRODUCT DETAILS-COLS
\*------------------------------------*/
.o-wrapper--product-details-cols {
  @extend .o-wrapper;
  @include wrapper.display(column);
  @include wrapper.column(1);
  @include wrapper.column(2, desktop);
}
/*------------------------------------*\
  # WRAPPER PRODUCT DETAILS
\*------------------------------------*/
.o-wrapper--contact-hub {
  @extend .o-wrapper;
  @include wrapper.direction(row);
  @include wrapper.justify(center);
  @include wrapper.display(layout);
  @include wrapper.column(2);
  @include wrapper.column(4, desktop);
}
/*------------------------------------*\
  # WRAPPER RESPONSIVE
\*------------------------------------*/
.o-wrapper--column--responsive {
  @include wrapper.columnResponsive;
}
/*------------------------------------*\
  # WRAPPER CENTERED
\*------------------------------------*/
.o-wrapper--centered {
  @include wrapper.centered;
}
/*------------------------------------*\
  # WRAPPER GRID SEPARATOR

  Gestion de l'affichage d'élement en mode
  grid
\*------------------------------------*/
%wrapper--separator {
  
}
.o-wrapper--separator {
  --wrapper-separator-color: theme('colors.gray.300');
  column-gap: calc(var(--wrapper-gap-column) * 2);
  & > * + * {
    @apply relative;
    &::before {
      @apply absolute top-0 h-full w-px;
      background-color: var(--wrapper-separator-color);

      content: '';
      left: calc(var(--wrapper-gap-column) * -1);
    }
  }
}
.o-wrapper--separator--tablet {
  @screen md-max { 
    & > * + *::before {
      @apply hidden;  
    }
  }
}
.o-wrapper--separator--dark {
  --wrapper-separator-color: theme('colors.gray.900');
}

/*------------------------------------*\
  # WRAPPER CENTER
\*------------------------------------*/
.o-wrapper--narrow { @screen md { @apply w-10/12; } }