@use '../3-tools/border' as border;
@use '../3-tools/button' as button;
@use '../3-tools/icons' as icons;
@use '../3-tools/links' as links;
@use '../3-tools/size' as size;
@use '../3-tools/typography' as typography;
@use '../3-tools/wrapper' as wrapper;

@use '../5-objects/o-wrapper';
@use '../6-components/c-text';
@use '../6-components/c-title';

/*------------------------------------*\
  #DEFAULT BUTTON
\*------------------------------------*/
.c-button {
  @include button.varsColors;
  /**
  @mixin  button.varsColors
  Definitions des vars générées:

  // Vars Global
  --button-default   : $button-default;
  --button-hover     : $button-hover;
  --button-contrasted: $button-contrasted;
  
  // Vars Background
  --button-bg-default: $button-bg-default;
  --button-bg-hover  : $button-bg-hover;
  
  // Vars Border
  --button-border-default: $button-border-default;
  --button-border-hover  : $button-border-hover;
  
  // Vars texts
  --button-text-default: $button-text-default;
  --button-text-hover  : $button-text-hover;
  
  // Vars Disabled
  --button-bg-disabled: $button-bg-disabled;
  --button-border-disabled: $button-border-disabled;
  --button-text-disabled: $button-text-disabled;
  */

  @include icons.size(20);
  @include links.underlineHover('false');

  @extend .c-text--button;
  
  @extend .o-wrapper !optional;
  @include wrapper.display(inline-flex);
  @include wrapper.direction(row);
  @include wrapper.gap(theme('gap.[1.5]'));
  @include wrapper.justify(center);
  @include wrapper.items(center);

  @apply rounded px-5 py-2.5 border border-solid;
  @apply transition duration-300;
  @apply text-center w-auto mx-0;

  @include icons.selector {
    @apply duration-0;
  }

  min-height: 46px;
  background-color: var(--button-bg-default);
  border-color: var(--button-border-default);
  color: var(--button-text-default);

  &:hover:not([disabled]):not(.c-button--disabled):not(.c-button--inline),
  &:focus-visible:not([disabled]):not(.c-button--disabled):not(.c-button--inline) {

    background-color: var(--button-bg-hover);
    border-color: var(--button-border-hover);
    color: var(--button-text-hover);
  }

  small {
    @apply text-gray-800;
  }
}
.c-button:is([disabled]),
.c-button--disabled {
  @apply cursor-default;


  background-color: var(--button-bg-disabled);
  border-color: var(--button-border-disabled);
  color: var(--button-text-disabled);

  &:hover, &:focus-visible, &[aria-current=true] {
    @apply no-underline;

    color: var(--button-text-disabled);
  }

}


/*------------------------------------*\
  #COLORS BUTTONS
\*------------------------------------*/

.c-button--theme {
  @include button.varsColors(
    var(--theme-color),             // button-default
    theme('colors.gray.800'),       // buton-hover
    theme('colors.white')           // button-contrasted
  );
}
.c-button--theme-dark {
  @include button.varsColors(
    var(--theme-color-dark),             // button-default
    theme('colors.gray.800'),       // buton-hover
    theme('colors.white')           // button-contrasted
  );
}
.c-button--part {
  @include button.varsColors(
    theme('colors.part.DEFAULT'),   // button-default
    theme('colors.gray.800'),      // buton-hover
    theme('colors.white')           // button-contrasted
  );
}
.c-button--pro {
  @include button.varsColors(
    theme('colors.pro.DEFAULT'),   // button-default
    theme('colors.gray.800'),      // buton-hover
    theme('colors.white')          // button-contrasted
  );
}
.c-button--agences {
  @include button.varsColors(
    theme('colors.agences.DEFAULT'),   // button-default
    theme('colors.gray.800'),      // buton-hover
    theme('colors.white')          // button-contrasted
  );
}
.c-button--showrooms {
  @include button.varsColors(
    theme('colors.showrooms.DEFAULT'),   // button-default
    theme('colors.gray.800'),      // buton-hover
    theme('colors.white')          // button-contrasted
  );
}
.c-button--white {
  @include button.varsColors(
    theme('colors.white'),      // button-default
    theme('colors.gray.200'),   // buton-hover
    theme('colors.gray.800')    // button-contrasted
  );
}
/*------------------------------------*\
  #MODIFIERS BUTTON - normal

  font-weight 400
\*------------------------------------*/
.c-button--normal {
  @extend .c-text--normal;
}
/*------------------------------------*\
  #MODIFIERS BUTTON - xs
\*------------------------------------*/
.c-button--xs {
  @extend .c-text--xs;
}

/*------------------------------------*\
  #MODIFIERS BUTTON - bordered
\*------------------------------------*/
.c-button--bordered {
  @apply bg-transparent;

  color: var(--button-default);
  border-color: var(--button-default);
  
  &:is([disabled]),
  &.c-button--disabled {
    @apply cursor-default;

    background-color: var(--button-bg-disabled);
    border-color: var(--button-border-disabled);
    color: var(--button-text-disabled);

    &:hover, &:focus {
      @apply no-underline;

      color: var(--button-text-disabled);
    }
  }
}

/*------------------------------------*\
  #MODIFIERS BUTTON - left
\*------------------------------------*/
.c-button--left {
  @apply text-left;
}

/*------------------------------------*\
  #MODIFIERS BUTTON - extra small
\*------------------------------------*/
.c-button--extra-small {
  @apply px-3 py-2 min-h-10;
}

/*------------------------------------*\
  #MODIFIERS BUTTON - small
\*------------------------------------*/
.c-button--small {
  @apply px-3 py-3;
}

/*------------------------------------*\
  #MODIFIERS BUTTON - full width
\*------------------------------------*/
.c-button--full {
  @apply w-full;
}

/*------------------------------------*\
  #MODIFIERS BUTTON - space between
\*------------------------------------*/
.c-button--between {
  @apply w-full;
  @include wrapper.justify(space-between);
}

/*------------------------------------*\
  #MODIFIERS BUTTON - icon

  button avec juste un icon dedans
\*------------------------------------*/
.c-button--icon-alone {
  @include icons.size(24);

  @apply p-2.5;

  &.c-button--small {
    @apply p-2;
  }
}
.c-button--icon-alone-md {
  @screen md-max {
    @apply w-full;
  }
  @screen md {
    @include icons.size(24);
  
    @apply p-2.5;
  
    &.c-button--small {
      @apply p-2;
    }
  }
}
/*------------------------------------*\
  #MODIFIERS BUTTON - icon big
\*------------------------------------*/
.c-button--big {
  @include icons.size(20);
}

/*------------------------------------*\
  #MODIFIERS BUTTON - sans border radius
\*------------------------------------*/
.c-button--rounded-none {
  @apply rounded-none;
}
/*------------------------------------*\
  #MODIFIERS BUTTON - rond
\*------------------------------------*/
.c-button--rounded-full {
  @apply rounded-full;
}

/*------------------------------------*\
  #MODIFIERS BUTTON - inline
\*------------------------------------*/
.c-button--inline,
.c-button--inline-sm,
.c-button--inline-lg,
.c-button--inline-search {
  @include wrapper.gap(xxxxs);

  @include icons.size(16);

  @extend .c-text--button-inline;
  
  color: var(--button-border-default);
  @apply border-t-0 border-l-0 border-r-0 rounded-none;
  @apply bg-transparent px-0 py-0.5 min-h-max;
  

  &:hover {
    color: var(--button-hover);
    border-color: var(--button-hover);
  }

  @screen md {
    @apply py-1;
  }
}
/*------------------------------------*\
  #MODIFIERS BUTTON - inline big
\*------------------------------------*/
.c-button--inline-sm {
  @apply py-0.5;
}

/*------------------------------------*\
  #MODIFIERS BUTTON - inline big
\*------------------------------------*/
.c-button--inline-lg {
  @include icons.size(22);

  @extend .c-text--button-inline-big;
  
  @apply py-1;
}

/*------------------------------------*\
  #MODIFIERS BUTTON - inline big
\*------------------------------------*/
.c-button--inline-search {
  --button-hover: var(--theme-color);

  @apply h-full text-left w-full;
  
  @extend .c-text--caption;
  @include wrapper.justify(space-between);
  
  &:not(.c-button--theme) {
    --button-border-default: #{theme('colors.gray.800')};
  }

  &[aria-current=true] {
    @apply cursor-default;
  }

  .c-button__indicator {
    @apply -mt-1;
  }
}
.c-button--storelocator {
  @include icons.selector {
    @apply hidden;
  }
  @screen storelocator {
    @include icons.selector {
      @apply inline-flex;
    }
    span {
      @apply hidden;
    }
  }
}
/*------------------------------------*\
  #MODIFIERS BUTTON - push
\*------------------------------------*/
.c-button--push {
  --button-bg-default: #{theme('colors.white')};
  --button-bg-hover: var(--theme-color-light);
  --button-text-default: #{theme('colors.gray.900')};
  --button-text-hover: #{theme('colors.gray.900')};
  --button-border-hover: var(--theme-color);
  @include wrapper.gap(xs);
  @include icons.size(16);

  @extend .c-button--full;
  @extend .c-button--rounded-none;
  @extend .c-text--xs;

  @apply uppercase p-5 border-0;
  min-height: 57px;

  @include border.bottom;

  span {
    @apply flex-grow text-left;
  }
  
  .c-icon--lg,
  img {
    width: 30px;
  }

  @screen md-max {
    @include icons.selector {
      &.c-icon--chevron-right{
        @apply hidden;
      }
    }
  }
}
.c-button--push-innoval {
  @screen md {
    @apply mt-gutter--mobile;
  }
  img {
    width: 80px;
  }
}
.c-button--push-gray--mobile {
  @screen md-max {
    --button-bg-default: #{theme('colors.gray.100')};
    --button-bg-hover: #{theme('colors.gray.200')};
  }
}

/*------------------------------------*\
  #MODIFIERS BUTTON - product
\*------------------------------------*/
.c-button--product {

  &:not(.c-button--theme) {
    --button-bg-default: #{theme('colors.white')};
    --button-bg-hover: #{theme('colors.gray.100')};
    --button-text-default: #{theme('colors.gray.900')};
    --button-text-hover: var(--theme-color);
  }
  
  @include icons.size(24);

  @extend .c-button--full;
  @extend .c-button--rounded-none;
  @extend .c-title--h5;

  @apply px-5 py-3 border-0 gap-5;
  
  @include border.bottom;

  min-height: 74px;

  &[aria-expanded=true] {
    --button-bg-default: #{theme('colors.gray.100')};
  }

  span {
    @apply flex-grow text-left;
  }
  i, img {
    min-width: 50px;
  }
  @screen md {
    @apply border-b-0;
  }
}

/*------------------------------------*\
  #MODIFIERS BUTTON - simple
\*------------------------------------*/
.c-button--simple {
  --button-bg-default: none;
  --button-bg-hover: none;
  --button-text-default: #{theme('colors.gray.900')};
  --button-text-hover: var(--theme-color);

  @include icons.size(20);
  @apply p-0 border-0 min-h-max;
}

/*------------------------------------*\
  #MODIFIERS BUTTON - simple
\*------------------------------------*/
.c-button--close {
  --button-bg-default: none;
  --button-bg-hover: none;
  --button-text-default: #{theme('colors.gray.900')};
  --button-text-hover: var(--theme-color);

  @include icons.size(32);
  @apply border-0 min-h-max;
  @apply p-2.5;
}