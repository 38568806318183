@mixin setParam ($param, $value, $query:false) {
  @if $query == 'tablet' {
    @screen md {
      #{$param}: $value;
    }
  }
  @else if $query == 'desktop' {
    @screen lg {
      #{$param}: $value;
    }
  }
  @else if $query == 'desktopXl' {
    @screen xl {
      #{$param}: $value;
    }
  }
  @else if $query == 'desktop2xL' {
    @media (min-width: 1536px) {
      #{$param}: $value;
    }
  }
  @else {
    #{$param}: $value;
  }
}
/*------------------------------------*\
  #WRAPPER DIRECTION

  - row
  - column
\*------------------------------------*/
@mixin direction ($value, $query:false) {
  @include setParam(--wrapper-direction, $value, $query);
}

/*------------------------------------*\
  #WRAPPER GAP

  - none 0px
  - xxxxs 4px
  - xxxs 8px
  - xxs 12px
  - xs 20px
  - sm 28px
  - md 32px
  - lg 48px
  - xl 56px
  - xxl 112px
\*------------------------------------*/
@function getGap ($value) {
  @if $value == none {
    $value: #{theme('gap.0')};
  } @else if $value == xxxxs {
    $value: #{theme('gap.1')};
  } @else if $value == xxxs {
    $value: #{theme('gap.2')};
  } @else if $value == xxs {
    $value: #{theme('gap.gutter--mobile')};
  } @else if $value == xs {
    $value: #{theme('gap.5')};
  } @else if $value == sm {
    $value: #{theme('gap.7')};
  } @else if $value == md {
    $value: #{theme('gap.gutter')};
  } @else if $value == lg {
    $value: #{theme('gap.12')};
  } @else if $value == xl {
    $value: #{theme('gap.14')};
  } @else if $value == xxl {
    $value: #{theme('gap.28')};
  }
  @return $value;
}
@mixin gapRow ($value, $query:false) {
  $value: getGap($value);
  @include setParam(--wrapper-gap-row, $value, $query);
}
@mixin gapColumn ($value, $query:false) {
  $value: getGap($value);
  @include setParam(--wrapper-gap-column, $value, $query);
}
@mixin gap ($value, $query:false) {
  @include gapRow($value, $query);
  @include gapColumn($value, $query);
}
/*------------------------------------*\
  #WRAPPER WRAP

  - start
  - end
  - center ...
\*------------------------------------*/
@mixin wrap ($value, $query:false) {
  @include setParam(--wrapper-wrap, $value, $query);
}
/*------------------------------------*\
  #WRAPPER JUSTIFY

  - between
  - start
  - end
  - center ...
\*------------------------------------*/
@mixin justify ($value, $query:false) {
  @include setParam(--wrapper-justify, $value, $query);
}
/*------------------------------------*\
  #WRAPPER ITEMS

  - start
  - end
  - center ...
\*------------------------------------*/
@mixin items ($value, $query:false) {
  @include setParam(--wrapper-items, $value, $query);
}
/*------------------------------------*\
  #WRAPPER COLUMNS
\*------------------------------------*/
@mixin columns ($value, $query:false) {
  @include setParam(--wrapper-column-count, $value, $query);
}
/*------------------------------------*\
  #WRAPPER DISPLAY

  - grid
  - columns
  - flex (default)
\*------------------------------------*/
@mixin display ($value, $query:false) {
  @if $value == grid {

    @include columns(1, $query);

    @include setParam(grid-template-columns, repeat(var(--wrapper-column-count), minmax(0, 1fr)), $query);
    @include setParam(--wrapper-display, $value, $query);
  
  } @else if $value == column {
  
    @include columns(1, $query);
    @include setParam(column-count, var(--wrapper-column-count), $query);
    @include setParam(--wrapper-display, $value, $query);
    @apply block;
    --wrapper-margin-top: var(--wrapper-gap-row);

    & > * {
      @apply break-inside-avoid;
      &:not(:first-child) {
        margin-top: var(--wrapper-margin-top);
      }
    }

  
  } @else if $value == layout {
    --wrapper-direction: row;
    row-gap: 0;
    column-gap: 0;
    width: calc(100% + var(--wrapper-gap-column));

    margin-left: calc((var(--wrapper-gap-column) / 2) * -1);
    margin-right: calc((var(--wrapper-gap-column) / 2) * -1);

    margin-top: calc((var(--wrapper-gap-row) / 2) * -1);
    margin-bottom: calc((var(--wrapper-gap-row) / 2) * -1);

    @apply flex-wrap;
    & > * {
      @apply flex;
      
      &:not(.o-wrapper--layout-free) {
        width: calc((100% / var(--wrapper-column-count)));
      }
      padding-left: calc(var(--wrapper-gap-column) / 2);
      padding-right: calc(var(--wrapper-gap-column) / 2);

      padding-top: calc(var(--wrapper-gap-row) / 2);
      padding-bottom: calc(var(--wrapper-gap-row) / 2);

    }
  
  } @else if $value == inline-flex  {
    @include setParam(--wrapper-display, $value, $query);
    @apply w-auto m-0 max-w-full;

  } @else  {
    @include setParam(--wrapper-display, $value, $query);
  }
}
/*------------------------------------*\
  #WRAPPER SPAN
\*------------------------------------*/
@mixin span ($direction, $value, $query:false) {
  @if $direction == column {
      @include setParam(grid-column, span var(--wrapper-span, 1), $query);  
  } @else if $direction == row {
    @include setParam(grid-row, span var(--wrapper-span, 1), $query);  
  }
  @include setParam(--wrapper-span, $value, $query);
}
/*------------------------------------*\
  #WRAPPER COLUMN
\*------------------------------------*/
@mixin column ($value, $query:false) {
  @include setParam(--wrapper-column-count, $value, $query);
}
/*------------------------------------*\
  #WRAPPER ITEM INLINE
\*------------------------------------*/
@mixin itemInline ($justify, $gap, $query:false) {
  @include display(inline-flex, $query);
  @include direction(row, $query);
  @include justify($justify, $query);
  @include items(center, $query);
  @include gapColumn($gap, $query);
}
/*------------------------------------*\
  #WRAPPER CENTERED
\*------------------------------------*/
@mixin centered ($direction:row, $query:false) {
  @include direction($direction, $query);
  @include justify(center, $query);
  @include items(center, $query);
  @include gap(none, $query);
}

/*------------------------------------*\
  # WRAPPER RESPONSIVE
\*------------------------------------*/
@mixin columnResponsive {
  @include column(1);
  @include column(2, tablet);
  @include column(4, desktop);
}
