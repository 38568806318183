.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
    transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-marker-shadow {
    width: 41px;
    height: 41px;
    pointer-events: none;
    position: absolute;
    left: -5px;
    top: -16px;
    background: url(../../img/data/store-locator/marker-shadow.png) no-repeat 0 0;
    z-index: 0;
    opacity: 0.8;
}

.marker-cluster,
.marker-default-icon {
    //transition: transform 0.3s ease-out, opacity 0.3s ease-in;

    --marker-border-color: var(--theme-color);
    --marker-bg-color: theme('colors.white');
    --marker-text-color: theme('colors.gray.900');

    .inside {
        background-color: var(--marker-bg-color);
        border: 3px solid var(--marker-border-color);
        color: var(--marker-text-color);
        border-radius: 50% 50% 50% 0;
        transform: rotate(-45deg);
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -12px 0 0 -12px;
        transition: all 200ms ease;
        z-index: 1;

        span {
            display: block;
            text-align: center;
            line-height: 30px;
            transform: rotate(45deg);
            font-size: 11px;
            margin: 0 auto;
            font-weight: bold;
        }
    }

    &.group-0 .inside { // showrooms
        // border-color: rgba(theme('colors.showrooms.DEFAULT'), 0.6);
        border-color: theme('colors.showrooms.DEFAULT');
        background-color: theme('colors.showrooms.DEFAULT');
    }
    &.group-0.selectedMarker .inside {
        background-color: theme('colors.showrooms.DEFAULT');
        border-color: white;

        &:before {
            display: none;
        }
    }

    &.group-1 .inside { // agences
        // border-color: rgba(theme('colors.agences.DEFAULT'), 0.6);
        border-color: theme('colors.agences.DEFAULT');
        background-color: theme('colors.agences.DEFAULT');
    }
    &.group-1.selectedMarker .inside {
        background-color: theme('colors.agences.DEFAULT');
        border-color: white;

        &:before {
            display: none;
        }
    }
}
.marker-cluster {
    .inside {
        background-color: var(--marker-border-color);
        --marker-text-color: theme('colors.white');
        width: 35px;
        height: 35px;
    }
    .leaflet-marker-shadow {
        left: 0;
        top: -5px;
    }
}
.marker-default-icon {
    .inside {
        width: 25px;
        height: 25px;

    }
    &.selectedMarker {
        z-index: 999999999 !important;
        .inside {
            background-color: var(--theme-color);
            border-color: white;
    
            &:before {
                display: none;
            }
        }
    }
}