@use '../3-tools/icons' as icons;
@use '../3-tools/links' as links;
@use '../3-tools/size' as size;
@use '../3-tools/typography' as typography;
@use '../3-tools/utils' as utils;

/*------------------------------------*\
  #ELEMENTS TITLES
\*------------------------------------*/
h1 {
  @include typography.fontFamily('Acumin');
  @include typography.fontSize(35px, 100%);
  @apply font-light normal-case;

  @screen lg {
    @include typography.fontSize(60px);
  }
}
h2 {
  @include typography.fontFamily('Acumin');
  @include typography.fontSize(24px, 110%);
  @apply font-bold normal-case;

  @screen lg {
    @include typography.fontSize(28px);
  }
}
h3 {
  @include typography.fontFamily('Acumin');
  @include typography.fontSize(18px, 120%);
  @apply font-semibold normal-case;

  @screen lg {
    @include typography.fontSize(22px);
  }
}
h4 {
  @include typography.fontFamily('Acumin');
  @include typography.fontSize(16px, 110%);
  @apply font-light normal-case;

  @screen lg {
    @include typography.fontSize(20px);
  }
}
h5 {
  @include typography.fontFamily('Acumin');
  @include typography.fontSize(14px, 120%);
  @apply font-light normal-case;

  @screen lg {
    @include typography.fontSize(16px);
  }
}
h6 {
  @include typography.fontFamily('Acumin');
  @include typography.fontSize(12px, 100%);
  @apply font-light normal-case;

  @screen lg {
    @include typography.fontSize(18px);
  }
}


/*------------------------------------*\
  #ELEMENTS LINKS
\*------------------------------------*/
a, button {
  @apply transition duration-200;
  @include utils.pointerEventsNone;
}
button{
  &:focus-visible, &:hover {
    color: var(--current-color);
  }
}
a{
  @include links.underline;
  &:focus-visible, &:hover {
    color: var(--current-color);
    span {
      @apply underline;
    }
  }
}

b, strong {
  @apply font-medium;
}