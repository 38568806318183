
/***********************************
* OLD CODE
***********************************/
.header-pro + .main-container {
    .search-locate & {
        height: var(--header-height);
    }
}


/* This layer icon has different margins */
/* In popup */
span.leaflet-pelias-layer-icon-container {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    vertical-align: text-bottom;
}

/* in results list */
// .leaflet-pelias-results span.leaflet-pelias-layer-icon-container {
//     margin-right: 18px;
// }

img.leaflet-pelias-layer-icon {
    width: 15px;
}

.leaflet-pelias-layer-icon {
    vertical-align: top;
}

.leaflet-pelias-layer-icon-point {
    width: 100%;
    height: 100%;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.leaflet-pelias-layer-icon-point {
    // TODO utilisé ????
    // @extend .icon-immovable;
    // @extend .icon-font;
    @apply text-gray-500;
    //background-image: url('../img/data/store-locator/point_icon.png');
}



.leaflet-pelias-hidden {
    display: none;
}


.leaflet-touch .leaflet-pelias-results.leaflet-bar {
    border-radius: 0;
    border: 0 none;
}


.leaflet-pelias-results {
    width: 100%;
    position: absolute;
    left: 0;
    top: 100%;
    display: none;
    border-radius: 0;
    border-top: 0;
    border-bottom: 0;
    max-height: inherit !important;
}

.leaflet-touch .leaflet-pelias-results {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}

/* For old IE, match other leaflet controls' appearance */
.leaflet-oldie .leaflet-pelias-results {
    border: 1px solid #999;
    left: -1px;
}


.leaflet-pelias-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.leaflet-pelias-results .leaflet-pelias-result {
    @apply border border-solid border-gray-500;
    font-size: 15px;
    padding: 8px 7px 9px 17px;
    background-color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.leaflet-pelias-results .leaflet-pelias-result:first-child {
    //border: none;
}

.leaflet-pelias-results .leaflet-pelias-result:hover {
    @apply bg-gray-100 border-gray-100;
}

.leaflet-pelias-results .leaflet-pelias-result.leaflet-pelias-selected,
.leaflet-pelias-results .leaflet-pelias-result.leaflet-pelias-selected:hover {
    @apply bg-gray-500 border-gray-500;
}

.leaflet-pelias-message {
    font-size: 13px;
    padding: 7px;
    background-color: white;
}

/* styles when positioned on right */

.leaflet-right .leaflet-pelias-results {
    left: auto;
    right: 0;
}

