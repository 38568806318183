@use '../3-tools/size' as size;
/*------------------------------------*\
  #LINKS UNDERLINE

  modification du la position du underline
\*------------------------------------*/
@mixin underline ($offset: auto) {

  @apply underline;
  text-decoration-skip-ink: auto;
  text-underline-offset: $offset;
  text-decoration-thickness: 1px;
}

@mixin underlineHover ($underline: "true") {
  @apply no-underline;
  
  &:focus, &:hover {
    span {
      @if $underline == "true" {
        @apply underline;
      }
      @else {
        @apply no-underline;
      }
    }
  }
}