
@font-face {
  font-family: 'icomoon';
  src:  url('/sites/all/themes/legrand/public/fonts/Icomoon/icomoon.eot?a6dz2v');
  src:  url('/sites/all/themes/legrand/public/fonts/Icomoon/icomoon.eot?a6dz2v#iefix') format('embedded-opentype'),
    url('/sites/all/themes/legrand/public/fonts/Icomoon/icomoon.ttf?a6dz2v') format('truetype'),
    url('/sites/all/themes/legrand/public/fonts/Icomoon/icomoon.woff?a6dz2v') format('woff'),
    url('/sites/all/themes/legrand/public/fonts/Icomoon/icomoon.svg?a6dz2v#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="c-icon--"], [class*=" c-icon--"], .c-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.c-icon--portiers:before {
  content: "\e99b";
}
.c-icon--mise-a-disposition-de-energie:before {
  content: "\e99c";
}
.c-icon--protection-distribution-grand-tertaire:before {
  content: "\e99d";
}
.c-icon--protection-distribution-grand-tertaire1:before {
  content: "\e99e";
}
.c-icon--residentielle:before {
  content: "\e99f";
}
.c-icon--habilitation-electrique:before {
  content: "\e994";
}
.c-icon--efficacite-energetique:before {
  content: "\e995";
}
.c-icon--vdi:before {
  content: "\e996";
}
.c-icon--securite:before {
  content: "\e997";
}
.c-icon--sante-hotel:before {
  content: "\e998";
}
.c-icon--wiring:before {
  content: "\e999";
}
.c-icon--formation-offre:before {
  content: "\e99a";
}
.c-icon--coeur:before {
  content: "\e911";
}
.c-icon--coeur-plein:before {
  content: "\e94c";
}
.c-icon--etoile:before {
  content: "\e91d";
}
.c-icon--etoile-pleine:before {
  content: "\e94d";
}
.c-icon--liste-materiel:before {
  content: "\e931";
}
.c-icon--liste-pleine:before {
  content: "\e94e";
}
.c-icon--chevron-left:before {
  content: "\e90c";
}
.c-icon--chevron-up:before {
  content: "\e90e";
}
.c-icon--chevron-down:before {
  content: "\e90b";
}
.c-icon--chevron-right:before {
  content: "\e90d";
}
.c-icon--check:before {
  content: "\e90a";
}
.c-icon--close:before {
  content: "\e90f";
}
.c-icon--back:before {
  content: "\e906";
}
.c-icon--up:before {
  content: "\e948";
}
.c-icon--down:before {
  content: "\e918";
}
.c-icon--next:before {
  content: "\e935";
}
.c-icon--filter:before {
  content: "\e922";
}
.c-icon--add:before {
  content: "\e93c";
}
.c-icon--less:before {
  content: "\e92d";
}
.c-icon--add-circle:before {
  content: "\e901";
}
.c-icon--less-circle:before {
  content: "\e92e";
}
.c-icon--geolocalisation:before {
  content: "\e925";
}
.c-icon--refresh:before {
  content: "\e93f";
}
.c-icon--menu:before {
  content: "\e934";
}
.c-icon--categorie:before {
  content: "\e908";
}
.c-icon--home:before {
  content: "\e928";
}
.c-icon--localisation:before {
  content: "\e933";
}
.c-icon--rechercher:before {
  content: "\e93e";
}
.c-icon--comparateur:before {
  content: "\e913";
}
.c-icon--comparateur-actif:before {
  content: "\e951";
}
.c-icon--liste-materiel-vide:before {
  content: "\e932";
}
.c-icon--sourd:before {
  content: "\e943";
}
.c-icon--telecharger:before {
  content: "\e944";
}
.c-icon--telephone:before {
  content: "\e945";
}
.c-icon--user:before {
  content: "\e949";
}
.c-icon--user-pro:before {
  content: "\e919";
}
.c-icon--acheter:before {
  content: "\e900";
}
.c-icon--code-barre:before {
  content: "\e910";
}
.c-icon--imprimante:before {
  content: "\e929";
}
.c-icon--piece-jointe:before {
  content: "\e93a";
}
.c-icon--poubelle:before {
  content: "\e93d";
}
.c-icon--oeil:before {
  content: "\e937";
}
.c-icon--video:before {
  content: "\e94a";
}
.c-icon--360:before {
  content: "\e94f";
}
.c-icon--3d:before {
  content: "\e950";
}
.c-icon--quotes:before {
  content: "\e956";
}
.c-icon--deconnexion:before {
  content: "\e952";
}
.c-icon--duplicate:before {
  content: "\e953";
}
.c-icon--edit:before {
  content: "\e955";
}
.c-icon--exporter:before {
  content: "\e957";
}
.c-icon--importer:before {
  content: "\e958";
}
.c-icon--parametres:before {
  content: "\e95a";
}
.c-icon--alerte:before {
  content: "\e95b";
}
.c-icon--produit-sup:before {
  content: "\e992";
}
.c-icon--choix:before {
  content: "\e993";
}
.c-icon--borne_recharge_electrique:before {
  content: "\e964";
}
.c-icon--interrupteur1:before {
  content: "\e965";
}
.c-icon--maison_connecte:before {
  content: "\e966";
}
.c-icon--moulure:before {
  content: "\e967";
}
.c-icon--portier:before {
  content: "\e968";
}
.c-icon--rallonge:before {
  content: "\e969";
}
.c-icon--tableau_electrique:before {
  content: "\e96a";
}
.c-icon--ajout-circuit-four:before {
  content: "\e96e";
}
.c-icon--ajout-circuit-lave-linge:before {
  content: "\e96f";
}
.c-icon--ajout-circuit-lave-vaisselle:before {
  content: "\e970";
}
.c-icon--ajout-circuit-seche-linge:before {
  content: "\e971";
}
.c-icon--ambiance-lumineuse:before {
  content: "\e972";
}
.c-icon--brancher-appareil-salle-de-bain:before {
  content: "\e973";
}
.c-icon--brancher-box:before {
  content: "\e974";
}
.c-icon--brancher-enceinte:before {
  content: "\e975";
}
.c-icon--brancher-equipement-exterieur:before {
  content: "\e976";
}
.c-icon--chasser-odeur-cuisson:before {
  content: "\e977";
}
.c-icon--commander-volets-roulants:before {
  content: "\e978";
}
.c-icon--controle-lumiere-geste:before {
  content: "\e979";
}
.c-icon--controler-eclairage-exterieur:before {
  content: "\e97a";
}
.c-icon--controler-lumiere:before {
  content: "\e97b";
}
.c-icon--controler-lumiere-smartphone:before {
  content: "\e97c";
}
.c-icon--controler-ventillation:before {
  content: "\e97d";
}
.c-icon--equiper-alarme-incendie:before {
  content: "\e97e";
}
.c-icon--equiper-reseau-multimedia:before {
  content: "\e97f";
}
.c-icon--interrupteur-automatique:before {
  content: "\e980";
}
.c-icon--mettre-en-route-chauffe-eau:before {
  content: "\e981";
}
.c-icon--multi-branchement:before {
  content: "\e982";
}
.c-icon--multiplier-prises-electriques:before {
  content: "\e983";
}
.c-icon--programmer-appareils-electriques:before {
  content: "\e984";
}
.c-icon--recharger-smartphone-USB-mural:before {
  content: "\e985";
}
.c-icon--recharge-USB:before {
  content: "\e986";
}
.c-icon--surveiller-consommations:before {
  content: "\e987";
}
.c-icon--thermostat-chauffage:before {
  content: "\e988";
}
.c-icon--alerte-smartphone-coupure-frigo:before {
  content: "\e989";
}
.c-icon--controle-distance-seche-serviette:before {
  content: "\e98a";
}
.c-icon--interrupteur-double-connecte:before {
  content: "\e98b";
}
.c-icon--recharger-plusieurs-appareils:before {
  content: "\e98c";
}
.c-icon--protection-installation:before {
  content: "\e98d";
}
.c-icon--coupure-electricite:before {
  content: "\e98e";
}
.c-icon--showroom:before {
  content: "\e95d";
}
.c-icon--inspire:before {
  content: "\e961";
}
.c-icon--prepare:before {
  content: "\e962";
}
.c-icon--carte-france:before {
  content: "\e95e";
}
.c-icon--eco-conception:before {
  content: "\e95f";
}
.c-icon--savoir-faire-local:before {
  content: "\e960";
}
.c-icon--guide-de-choix:before {
  content: "\e927";
}
.c-icon--agrements:before {
  content: "\e902";
}
.c-icon--aide:before {
  content: "\e903";
}
.c-icon--application:before {
  content: "\e904";
}
.c-icon--autre:before {
  content: "\e905";
}
.c-icon--catalogue:before {
  content: "\e907";
}
.c-icon--certification:before {
  content: "\e909";
}
.c-icon--commander:before {
  content: "\e912";
}
.c-icon--completer-demande:before {
  content: "\e914";
}
.c-icon--declaration-conformite:before {
  content: "\e915";
}
.c-icon--demarrer:before {
  content: "\e916";
}
.c-icon--documentation:before {
  content: "\e917";
}
.c-icon--enveloppe:before {
  content: "\e91a";
}
.c-icon--environnement:before {
  content: "\e91b";
}
.c-icon--erreur:before {
  content: "\e91c";
}
.c-icon--etude:before {
  content: "\e91e";
}
.c-icon--fax:before {
  content: "\e920";
}
.c-icon--fiche-technique:before {
  content: "\e921";
}
.c-icon--formation:before {
  content: "\e923";
}
.c-icon--garantie:before {
  content: "\e924";
}
.c-icon--guide:before {
  content: "\e926";
}
.c-icon--insatisfaction:before {
  content: "\e92a";
}
.c-icon--interrupteur:before {
  content: "\e92c";
}
.c-icon--mosaic:before {
  content: "\e98f";
}
.c-icon--art-d-arnould:before {
  content: "\e990";
}
.c-icon--altege:before {
  content: "\e991";
}
.c-icon--licence:before {
  content: "\e92f";
}
.c-icon--notice:before {
  content: "\e936";
}
.c-icon--outils:before {
  content: "\e938";
}
.c-icon--dificulte:before {
  content: "\e954";
}
.c-icon--pep:before {
  content: "\e939";
}
.c-icon--s-abonner:before {
  content: "\e940";
}
.c-icon--schema:before {
  content: "\e941";
}
.c-icon--shop:before {
  content: "\e942";
}
.c-icon--temps:before {
  content: "\e946";
}
.c-icon--unknow:before {
  content: "\e947";
}
.c-icon--coupure:before {
  content: "\e959";
}
.c-icon--youtube:before {
  content: "\e96c";
}
.c-icon--facebook:before {
  content: "\e91f";
}
.c-icon--instagram:before {
  content: "\e92b";
}
.c-icon--linkedin:before {
  content: "\e930";
}
.c-icon--pinterest:before {
  content: "\e93b";
}
.c-icon--x:before {
  content: "\e94b";
}
.c-icon--tiktok:before {
  content: "\e9a0";
}
.c-icon--bticino:before {
  content: "\e95c";
}
.c-icon--logo-legrand:before {
  content: "\e96b";
}
.c-icon--logo-improvinglives:before {
  content: "\e96d";
}
.c-icon--direction:before {
  content: "\e963";
}
