@use '../3-tools/typography' as typography;


/*------------------------------------*\
# TEXTE CAPTION ALL
\*------------------------------------*/
.c-text--caption,
.c-text--caption-sm,
.c-text--caption-lg {
  @apply inline-flex text-gray-600 uppercase tracking-xs;
  @include typography.fontFamily('Roboto');
}
/*------------------------------------*\
# TEXTE CAPTION ALL
\*------------------------------------*/
.c-text--caption-white {
  @apply text-white;
}
/*------------------------------------*\
# TEXTE CAPTION DEFAULT
\*------------------------------------*/
.c-text--caption {
  @apply font-medium;
  @include typography.fontSize(10, 135%);

  @screen lg {
    @include typography.fontSize(12);
  }
}
/*------------------------------------*\
# TEXTE CAPTION LARGE - sm
\*------------------------------------*/
.c-text--caption-sm {
  @apply font-medium;
  @include typography.fontSize(10, 135%);

  @screen lg {
    @include typography.fontSize(10);
  }
}
/*------------------------------------*\
# TEXTE CAPTION LARGE - lg
\*------------------------------------*/
.c-text--caption-lg {
  @apply font-normal;
  @include typography.fontSize(12, 135%);

  @screen lg {
    @include typography.fontSize(12);
  }
}

/*------------------------------------*\
# TEXTE SWITCH THEME - small
\*------------------------------------*/
.c-text--switch-theme-sm {
  @apply font-normal;
  @include typography.fontSize(11, 145%);
}

/*------------------------------------*\
# TEXTE SWITCH THEME - medium
\*------------------------------------*/
.c-text--switch-theme-md {
  @apply font-normal;
  @include typography.fontSize(14, 114%);
}

/*------------------------------------*\
# TEXTE INDICATOR
\*------------------------------------*/
.c-text--indicator {
  @apply font-medium;
  @include typography.fontSize(10, 135%);
}

/*------------------------------------*\
# TEXTE LEGEND
\*------------------------------------*/
.c-text--legend {
  @extend .c-text--gray-500;
  @extend .c-text--xxs;
}

/*------------------------------------*\
# TEXTE THEME
\*------------------------------------*/
.c-text--theme {
  color: var(--theme-color);
}

/*------------------------------------*\
# TEXTE GRAY-900
\*------------------------------------*/
.c-text--gray-900 {
  color: theme('colors.gray.900');
}
/*------------------------------------*\
# TEXTE GRAY-700
\*------------------------------------*/
.c-text--gray-700 {
  color: theme('colors.gray.700');
}
/*------------------------------------*\
# TEXTE GRAY-600
\*------------------------------------*/
.c-text--gray-600 {
  color: theme('colors.gray.600');
}
/*------------------------------------*\
# TEXTE GRAY-500
\*------------------------------------*/
.c-text--gray-500 {
  color: theme('colors.gray.500');
}

/*------------------------------------*\
# TEXTE TESTIMONIAL
\*------------------------------------*/
.c-text--testimonial {
  @include typography.fontFamily('Acumin');
  @include typography.fontSize(18, 120%);
  @screen lg {
    @include typography.fontSize(24, 110%);
  }
    @apply font-light;
}

/*------------------------------------*\
# TEXTE BUTTONS
\*------------------------------------*/
.c-text--button {
  --font-weight: 500;
  
  @include typography.fontSize(16, 135%);

  font-weight: var(--font-weight);

  @screen lg {
    @include typography.fontSize(16);
  }
}

/*------------------------------------*\
# TEXTE BUTTONS INLINE
\*------------------------------------*/
.c-text--button-inline {
  @include typography.fontSize(14, 135%);

  @screen lg {
    @include typography.fontSize(14);
  }
}

/*------------------------------------*\
# TEXTE BUTTONS INLINE BIG
\*------------------------------------*/
.c-text--button-inline-big {
  @include typography.fontSize(16, 112%);

  @screen lg {
    @include typography.fontSize(16);
  }
}

/*------------------------------------*\
# TEXTE MD - medium
\*------------------------------------*/
.c-text, .c-text--md {
  @apply font-normal;
  @include typography.fontSize(14, 150%);

  @screen lg {
    @include typography.fontSize(16, 150%);
  }
}

/*------------------------------------*\
# TEXTE SM - small
\*------------------------------------*/
.c-text--sm {
  @apply font-normal;
  @include typography.fontSize(12, 150%);

  @screen lg {
    @include typography.fontSize(14, 150%);
  }
}

/*------------------------------------*\
# TEXTE XS - extra small
\*------------------------------------*/
.c-text--xs {
  @apply font-normal;
  @include typography.fontSize(10, 150%);

  @screen lg {
    @include typography.fontSize(12, 150%);
  }
}

/*------------------------------------*\
# TEXTE XXS - extra extra small
\*------------------------------------*/
.c-text--xxs {
  @apply font-normal;
  @include typography.fontSize(10 , 120%);

  @screen lg {
    @include typography.fontSize(12, 120%);
  }
}

/*------------------------------------*\
# TEXTE NAVBAR ITEM MAIN
\*------------------------------------*/
.c-text--navbar-item-main {
  @apply font-normal;
  @include typography.fontSize(16, 120%);

  @screen lg {
    @include typography.fontSize(14, 150%);
  }
}

/*------------------------------------*\
# TEXTE LIGHT
\*------------------------------------*/
.c-text--light {
  @apply font-light;
}


/*------------------------------------*\
# TEXTE NORMAL
\*------------------------------------*/
.c-text--normal {
  @apply font-normal;
}


/*------------------------------------*\
# TEXTE MEDIUM
\*------------------------------------*/
.c-text--medium {
  @apply font-medium;
}

/*------------------------------------*\
# TEXTE BOLD
\*------------------------------------*/
.c-text--bold {
  @apply font-bold;
}

/*------------------------------------*\
# TEXTE NO WRAP
\*------------------------------------*/
.c-text--nowrap {
  @apply whitespace-nowrap;
}

/*------------------------------------*\
# TEXTE UPPERCASE
\*------------------------------------*/
.c-text--uppercase {
  @apply uppercase;
}

/*------------------------------------*\
# TEXTE LEFT
\*------------------------------------*/
.c-text--left {
  @apply text-left;
}
/*------------------------------------*\
# TEXTE CENTER
\*------------------------------------*/
.c-text--center {
  @apply text-center;
}
/*------------------------------------*\
# TEXTE RIGHT
\*------------------------------------*/
.c-text--right {
  @apply text-right;
}

/*------------------------------------*\
# TEXTE NO-UNDERLINE
\*------------------------------------*/
.c-text--no-underline {
  @apply no-underline;
}

/*------------------------------------*\
# TEXTE UNDERLINE
\*------------------------------------*/
.c-text--underline {
  @apply underline;
}


/*------------------------------------*\
# TEXT SEE ALL CATEGORY
\*------------------------------------*/
.c-text--see-all-category {
  @apply font-bold;
  @include typography.fontSize(12, 150%);

  @screen lg {
    @apply font-light normal-case;
    @include typography.fontSize(20px, 110%);
  }
}