@use '../3-tools/border' as border;
@use '../3-tools/icons' as icons;
@use '../3-tools/wrapper' as wrapper;

@use '../5-objects/o-wrapper';

@use '../6-components/c-button';
@use '../6-components/c-title';

/*------------------------------------*\
  STORELOCATOR-POPUP
\*------------------------------------*/
.c-storelocator-popup {
}

  /*------------------------------------*\
    STORELOCATOR-POPUP TITLE
  \*------------------------------------*/
  .c-storelocator-popup__title {
    @extend .c-title--h3;
  }

  /*------------------------------------*\
    STORELOCATOR-POPUP LINE
  \*------------------------------------*/
  .c-storelocator-popup__line {
    @extend .o-wrapper;
    @include wrapper.gap(xxxs);
    @include wrapper.direction(row);

    @extend .c-text--sm;
    @extend .c-text--light;

  }

.leaflet-popup-content-wrapper {
  @apply shadow-popin border border-solid border-gray-300;
}
.leaflet-popup-tip-container {
  @apply z-2 -mt-px;
}