@use '../3-tools/icons' as icons;
@use '../3-tools/wrapper' as wrapper;

@use '../5-objects/o-wrapper';

/*------------------------------------*\
  #VARS COLORS

  Génération de toutes les variables
  colors + reset neccessaire au 
  fonctionnement du button
\*------------------------------------*/
@mixin varsColors (
  $button-default: theme('colors.black'),
  $button-hover: theme('colors.gray.700'),
  $button-contrasted: theme('colors.white'),
  //  Background
  $button-bg-default: $button-default,
  $button-bg-hover: $button-hover,
  //  Border
  $button-border-default: $button-default,
  $button-border-hover: $button-hover,
  //  Texts
  $button-text-default: $button-contrasted,
  $button-text-hover: $button-contrasted,
  //  Disabled
  $button-bg-disabled: theme('colors.gray.200'),
  $button-border-disabled:  theme('colors.gray.200'),
  $button-text-disabled:  theme('colors.gray.600'),
) {
  // Reset Background
  @if $button-bg-default == default { $button-bg-default: #{$button-default}; }
  @if $button-bg-hover   == default { $button-bg-hover: #{$button-hover}; }

  // Reset Border
  @if $button-border-default == default { $button-border-default: #{$button-default}; }
  @if $button-border-hover   == default { $button-border-hover: #{$button-hover}; }

  // Reset Texts
  @if $button-text-default == default { $button-text-default: #{$button-contrasted}; }
  @if $button-text-hover   == default { $button-text-hover: #{$button-contrasted}; }

  // Reset Disabled
  @if $button-bg-disabled     == default { $button-bg-disabled: theme('colors.gray.200'); }
  @if $button-border-disabled == default { $button-border-disabled: theme('colors.gray.200'); }
  @if $button-text-disabled   == default { $button-text-disabled: theme('colors.gray.600'); }

  // Vars Global
  --button-default   : #{$button-default};
  --button-hover     : #{$button-hover};
  --button-contrasted: #{$button-contrasted};
  // Vars Background
  --button-bg-default: #{$button-bg-default};
  --button-bg-hover  : #{$button-bg-hover};
  // Vars Border
  --button-border-default: #{$button-border-default};
  --button-border-hover  : #{$button-border-hover};
  // Vars texts
  --button-text-default: #{$button-text-default};
  --button-text-hover  : #{$button-text-hover};
  // Vars Disabled
  --button-bg-disabled: #{$button-bg-disabled};
  --button-border-disabled: #{$button-border-disabled};
  --button-text-disabled: #{$button-text-disabled};
}

@mixin footerMobile  {
  @extend .o-wrapper;
  @include wrapper.direction(row);
  @include wrapper.justify(space-between);
  @include wrapper.justify(flex-start, desktop);

  @apply w-full;
  @apply px-5 py-gutter;
      
  @screen md {
    @apply px-gutter;
  }

  @screen lg {
    @apply p-0;
  }
  
  @include icons.selector {
    @apply transition transform duration-500;

    @screen lg {
      @apply hidden;
    }
  }
  &[aria-expanded=true] {
    @include icons.selector {
      @apply rotate-180;
    }
  }
}