@use '../3-tools/border';
@use '../3-tools/scrollbar';
@use '../3-tools/wrapper';

@use '../5-objects/o-wrapper';
@use '../6-components/c-title';
/*------------------------------------*\
  #FORM STORELOCATOR
\*------------------------------------*/
.m-storelocator {
  --m-storelocator-height: calc(100vh - var(--header-height) - 100px);
  --m-storelocator-sidebar-height: 570px;
  --m-storelocator-sidebar-width: 400px;

  @apply relative;
}

  /*------------------------------------*\
    #FORM STORELOCATOR__MAP
  \*------------------------------------*/
  .m-storelocator__map {
    height: var(--m-storelocator-height);

    @apply flex cursor-grab relative;

    &:not(.mobile-show-map) {
      @screen md-max {
        @apply absolute w-full;
        left: -9999999px;
      }
    }
  }

  /*------------------------------------*\
    #FORM STORELOCATOR__SIDEBAR
  \*------------------------------------*/
  .m-storelocator__sidebar {
    @screen md {
      @apply absolute left-10 shadow-popin z-20;
      top: 12%;
      width: 30%;
      max-width: var(--m-storelocator-sidebar-width);
      max-height: calc(var(--m-storelocator-height) - 24%);
    }
    @apply bg-white z-1;
    @apply flex flex-col;
    
    max-height: var(--m-storelocator-sidebar-height);
  }

    /*------------------------------------*\
      #FORM STORELOCATOR__TITLE
    \*------------------------------------*/
    .m-storelocator__title {
      @apply flex flex-col;
    }

      /*------------------------------------*\
        #FORM STORELOCATOR__TITLE
      \*------------------------------------*/
      .m-storelocator__title-main {
        @extend .c-title--card-xl;
        @media screen and (min-width: 768px) and (max-height: 860px) {
          @apply text-base;
        }
      }

      /*------------------------------------*\
        #FORM STORELOCATOR__RESULTS
      \*------------------------------------*/
      .m-storelocator__results {
        @media screen and (min-width: 768px) and (max-height: 860px) {
          @apply text-xs;
        }
      }

    /*------------------------------------*\
      #FORM STORELOCATOR__TITLE
    \*------------------------------------*/
    .m-storelocator__switch {
      @extend .o-wrapper;
      @include wrapper.gap(xxxs);
      @include wrapper.direction(row, tablet);
    }

    /*------------------------------------*\
      #FORM STORELOCATOR__SEARCH
    \*------------------------------------*/
    .m-storelocator__search {
      @extend .o-wrapper;
      @include wrapper.gapRow(xs);
      @include border.bottom;

      @apply p-gutter;

      @screen storelocator {
        @include wrapper.gapRow(xxxs);
        @apply py-4;
      }
    }

    /*------------------------------------*\
      #FORM STORELOCATOR__LIST
    \*------------------------------------*/
    .m-storelocator__list {
      @include scrollbar.scrollbar;
  
      @apply flex flex-col;
      @apply overflow-auto;

      &.item-selected .item:not(.active) {
        @apply hidden;
      }

      @screen md-max {
        @include border.bottom;
        &:not(.active) {
          @apply hidden;
        }
      }
    }


    /*------------------------------------*\
      #FORM STORELOCATOR__MOBILE CONTROLS
    \*------------------------------------*/
    .m-storelocator__mobile-controls {
      @extend .o-wrapper;
      @include wrapper.direction(row);
      @include wrapper.justify(center);
      @screen md {
        @apply hidden;
      }
    }