@use "sass:math";
@use 'sass:meta';
@use "sass:string";
@use '../3-tools/size' as size;

/*------------------------------------*\
# FONT SIZE
\*------------------------------------*/

@mixin fontSize ($size, $lineHeight: false) {
  font-size: size.toRem($size);
  @if $lineHeight != false {
    @if meta.type-of($lineHeight) == 'number' and math.unit($lineHeight) != '%' {
      line-height: size.toRem($lineHeight);
    } @else {
      line-height: $lineHeight;
    }
  }
}

/*------------------------------------*\
# FONT FAMILY
\*------------------------------------*/
@mixin fontFamily ($name: 'Roboto') {
  @if $name == 'Acumin' {
    font-family: "Acumin", sans-serif;
  }
  @else {
    font-family: "Roboto", sans-serif;
  }
}

